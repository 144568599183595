@use "theme-2015";

@use "site-uber" as site;
@use "theme-uber-past";

@use "subscribe-2024";


.orange-button {
  background: site.$orange;
  color: yellow;
  padding: 10px 25px;
  border-radius: 4px;
}
.orange-button:hover {
  color: #fff;
  text-decoration: none;
}

.speaker-circle-sm, .speaker-circle-md {
  img { border-color: site.$orange; }
}

body.show-home {
  font-family: 'Open-Sans', Helvetica, Arial, Sans-Serif;

  div.body-wrapper {
    background: #000;
    color: #fff;
  }

  div.level {
    padding: 60px 0 60px 0;
    //border-bottom: 1px solid #999;

    h3 {
      color: #fff;
      font-weight: normal;
      font-family: Lato, 'Open Sans', Sans-serif;
      font-size: 32px;
      margin-bottom: 30px;
    }

    p {
      span { color: site.$orange; }
    }
  }

  div.welcome {
      //min-height: 500px;

    div.container {
      text-align: center;
    }

    div#welcome-container {
      display: table;
      //border: 1px solid #fff;

      div.intro-wrapper {
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
      }
    }

    video {
      display: inline-block;
      //border: 1px solid #999;
      /*max-width: 512px;
      max-height: 288px;*/

      width: 768px;
      max-width: 100%;
      //max-height: 432px;

      //margin: 0 auto 0 auto;
      //border: 1px solid #fff;
      margin: 0;

    }

    video::-webkit-media-controls {
      display: none !important;
    }
    video::-webkit-media-controls-start-playback-button {
      display: none !important;
    }


    div#static-header {
      display: none;
      margin: 0;

      img.uber-intro {
        width: 768px;
        max-width: 100%;
        //border: 1px solid #fff;
      }

    }

    div#static-text {
      display: inline-block;
      opacity: 0;

      color: #fff;

      h1 { display: none; }
      h2 { color: #fff; font-weight: normal; font-size: 32px; font-family: 'Open Sans', Sans-serif; margin-bottom: 20px; }
      h2.dates { margin-bottom: 40px; }
      h3 { color: #fff; font-weight: normal; font-family: Roboto, 'Open Sans', Arial, Sans-Serif; font-size: 20px; margin-bottom: 30px; }
      h3.savings { color: site.$orange; }

      //a.orange-button.register {
      //  margin-bottom: 30px;
      //  display: inline-block;
      //}


      div.triple {
        h3 { color: site.$orange; margin: 0 0 5px 0; }
        //p { margin-bottom: 5px; }

      }

    }



  }

  div.lead {

    div.counter {
      //color: site.$orange;
      color: #3D8CBD;
      table {
        display: inline-block;
        td {
          margin-right: 10px;
          //min-width: 85px;
        }
        td>div {
          padding: 3px;
          h4 {
            border: 1px solid #3D8CBD;
            padding: 10px;
            display: inline-block;
          }
        }
      }
    } /* counter */

    div.reg-includes.amazon.small {
      display: table;
      max-width: 100%;
      margin-bottom: 30px;
      background: #fff;
      border: 1px solid site.$orange;
      border-radius: 4px;
      padding: 1px;
      text-align: left;

      h3 { font-size: 14px; color: #000; font-weight: normal; margin-bottom: 0; }


      div.image {
        display: table-cell;
        max-width: 120px;
        vertical-align: middle;
        margin-right: 10px;
        img { max-width: 100%; }
      }
      div.text {
        display: table-cell;
        padding-left: 10px;
        vertical-align: middle;
        max-width: 200px;
      }

    }

    div.video-replay {
      display: inline-block;
      max-width: 300px;
      background: #fff;
      padding: 15px 10px;
      border: 1px solid site.$orange;
      border-radius: 4px;
      margin: 0 0 20px 0;
      color: #000;
      text-align: center;
      h4 { font-size: 16px; font-weight: bold; margin-bottom: 5px; }
    }

    @media only screen and (max-width: site.$large) {
      div.about { margin-bottom: 40px; }
    }

  } // lead

  div.virtual-credits.level {

    h3 { margin-bottom: 10px; font-size: 24px; }
    h4 { color: site.$orange; font-size: 18px; font-weight: normal; margin-bottom: 30px; }

    div.image-wrapper {
      display: table;
      height: 100%;
      width: 100%;

      div.image-cell {
        display: table-cell;
        vertical-align: middle;
        img { max-width: 100%; }
      }
    }

    @media only screen and (max-width: site.$medium) {
      div.image-wrapper { margin-bottom: 20px; }
    }


  } // virtual-credits

  div.compare.level {

    table {
      //border: 1px solid #999;
      margin-bottom: 40px;
      width: 100%;
      max-width: 800px;
      //background: #fff;
      color: #fff;
      th, td {
        padding: 4px 8px;
      }
      td {
        //border-top: 1px solid #dee2e6;
        //border-top: 1px solid #fff;
        i.fa-check { color: #04bd04; font-size: 25px; }
        i.fa-times { color: #999; font-size: 20px; }
      }
      tbody {
        tr:nth-of-type(odd) {
          background-color: rgba(0,0,0,.05);
        }
      }
    }

    p {
      max-width: 800px;
    }

  } // compare

  div.level.last-year {
    text-align: center;

    div.video-replay {
      display: inline-block;
      max-width: 300px;
      background: #fff;
      padding: 15px 10px;
      border: 1px solid site.$orange;
      border-radius: 4px;
      margin: 0 0 20px 0;
      color: #000;
      text-align: center;
      h4 { font-size: 16px; font-weight: bold; margin-bottom: 5px; }
    }




  }

  div.why-contact {

    div.pdf-section {
      p {
        margin-bottom: 30px;
      }
      a.pdf-icon {
        margin-right: 15px;
      }
      a.orange-button { margin-bottom: 30px; }
    }

    @media only screen and (max-width: 991px) {
      div.pdf-section {
        margin-bottom: 30px;
        a.pdf-icon { display: none; }
        a.orange-button { font-size: 14px; }
      }
    }

    @media only screen and (max-width: 400px) {
      div.pdf-section {
        a.orange-button { font-size: 14px;  }
        a.pdf-icon { display: none; }
      }
    }

    div.subscribe-form {
      h3 {
        color: #000; font-weight: normal;
        margin-bottom: 5px;
      }
    }


  } // why-contact

  div.speakers.level {

    background: #fff;
    color: #000;

    h3 { color: #000; }

    div.speaker {
      text-align: center;
      margin-bottom: 10px;
      h4, h5 { text-weight: normal; }
      h4 { font-size: 16px; margin-bottom: 2px; }
      h5 { font-size: 14px; }
      .speaker-circle-md {
        //img {
        //  max-width: 100%;
        //}
      }
    }

  } // speakers

  div.free-workshop {

    header {
      h4 { color: site.$orange; font-size: 18px; }
      h3 { font-size: 24px; }
    }

    div.button-wrapper {
      text-align: center;
    }

    div.speakers {
      text-align: center;
      margin-bottom: 30px;
    }

    div.sponsored-by {
      a>img {
        border: 1px solid site.$orange;
        border-radius: 4px;
        padding: 3px;
        background: #fff;
      }
    }

    @media (max-width: 575px) {

      div.summary {
        margin-bottom: 30px;
      }

      div.speaker {
        display: inline-block;
        max-width: 150px;
      }
    }

  } // free-workshop

  div.workshops {

    p.price {
      color: site.$orange;
    }

    table {
      width: 100%;
      td.image { width: 80px; padding-bottom: 10px; }
      td.about {
        h4 { margin-bottom: 5px; }
        h5 { font-size: 14px; margin: 0 0 0 10px; }
      }
    }


  } // workshops

  div.travel {

    min-height: 400px;

    div.hotel {
      padding: 20px;
      background: rgba(255, 255, 255, 0.7);
      border-radius: 4px;
      color: #000;
      max-width: 400px;

      h3 { color: #000;margin-bottom: 5px; }
      h4 { font-size: 16px; font-weight: bold; margin-bottom: 5px; }
      p {
        margin-bottom: 20px;
      }
      a.orange-button {
        display: inline-block;
      }

    }

    @media only screen and (min-width: 3001px) {
      background: url(site.$cdn_nfjs + '/styles/2024/uber/westin_westminster_4000.jpg') no-repeat top center;
    }
    @media only screen and (max-width: 3000px) {
      background: url(site.$cdn_nfjs + '/styles/2024/uber/westin_westminster_3000.jpg') no-repeat top center;
    }
    @media only screen and (max-width: 2000px) {
      background: url(site.$cdn_nfjs + '/styles/2024/uber/westin_westminster_2000.jpg') no-repeat top center;
    }
    @media only screen and (max-width: 1600px) {
      background: url(site.$cdn_nfjs + '/styles/2024/uber/westin_westminster_1600.jpg') no-repeat top center;
    }
    @media only screen and (max-width: 1200px) {
        background: url(site.$cdn_nfjs + '/styles/2024/uber/westin_westminster_1200.jpg') no-repeat top center;
    }
    @media only screen and (max-width: 800px) {
      background: url(site.$cdn_nfjs + '/styles/2024/uber/westin_westminster_800.jpg') no-repeat top center;
    }


  } // travel

  div.past {
    ul {
      li { list-style: none; }
    }
  }

}

body.show-home, body.show-view {

  div.sponsors {
    background: #fff;
    color: #000;
    text-align: left;

    h3 { color: #000; font-size: 32px; }

    div.codescene {
      padding-top: 8px;
    }
    div.azul {
      img {
        max-width: 256px;
        max-height: 66px;
      }
    }

  }
  


}


body.show-view {

  div.body-wrapper {
    background: #000;
    color: #fff;
  }

  div.body {
    padding: 60px 0 60px 0;
  }

  h1, h2, h3 { color: #fff; }
  h1 { font-size: 46px; }

  div.header {
    margin-bottom: 30px;
    h1 {
      margin-bottom: 10px; color: site.$orange;
    }
    h2 { margin-left: 20px; }
  }

  nav#nav-bar {
    background: #000;
    margin-bottom: 30px;

    div.home-link {
      a {
        img { width: 200px; }
      }
    }

  }

  div.session-list {
    div.topics {
      h5.subtitle { margin-left: 20px; }
    }
  }


  div.travel-page {

    div.sold-out {
      background: #fff;
      padding: 10px;
      border: 1px solid site.$orange;
      border-radius: 4px;
      margin-bottom: 30px;
      color: #000;

      h4 { color: #cc0000; font-weight: bold; }
      a:hover { color: #cc0000; }
    }

    div.top {
      div.image {

        img {
          width: 100%;
          margin-bottom: 15px;
          border-radius: 4px;
        }

      }

    }

  }

  div.social {
    display: table;
    div.link-wrapper {
      display: table-cell;
      vertical-align: middle;

      div.app {
        border-right: 1px solid #fff;
        display: inline;
        margin-right: 10px;
      }
      div.social-links {
        display: inline;
        margin-left: 10px;
      }

      h3 { display: inline; font-size: 32px; margin-right: 10px; font-weight: normal; }
      i { font-size: 32px; margin-right: 10px; }
    }
  }

  div.free-workshop {  // free workshop page

    header {
      h2 { color: site.$orange; }
      h1 { font-size: 28px; }
    }

    h3 { color: site.$orange; }

    form {
      button:hover { background: site.$orange; color: yellow; }
    }


    #regForm {
      background: #fff;
      color: #000;
      border: 2px solid site.$orange;
      border-radius: 4px;
      padding: 10px;
      max-width: 400px;

      div.reg-form {
        h3 { color: #000;  }

        button {
          background: site.$orange;
          color: #fff;
        }
      }

    }


  }

  div.speaker-view-2020 {

    div.presentations-long {
      h4.subtitle {
        margin: 0 0 20px 20px;

      }
    }


  }

} // body.show-view

div.workshop-list-page {
  p.workshop-price {
    color: yellow;
  }
}

div.speaker-view-2020 {

  div.image-side {
    text-align: center;
    //img { max-width: 100%; }
  }
  //div.speaker-link-group-2020 {
  //  text-align: center;
  //}
}


div.public-video-page {

  div.show-info {
    h2 { color: site.$orange; }
  }

  header {
    margin: 0 0 30px 0;
    h1 { margin-bottom: 10px; font-size: 32px; }
    h3 { font-weight: 300; margin: 0 0 0 20px; }
  }

  video {
    border: 1px solid #999;
    max-width: 100%;
    width: 100%;
    margin-bottom: 40px;
  }

  div.speaker {
    background: #fff;
    color: #000;
    border: 1px solid site.$orange;
    border-radius: 4px;
    padding: 15px;
    text-align: center;

    h4 {
      font-weight: bold;
      a { color: #000; }
      a:hover { color: site.$orange; }
    }
  }



}

