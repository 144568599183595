
body.show-home {

  div.welcome-past {
    text-align: center;
    padding: 60px 0 60px 0;

    img {
      display: inline-block;
      width: 100%;
      max-width: 750px;
      margin-bottom: 20px;
    }

    h3 { font-family: 'Open Sans', Sans-serif; }
    h4 { margin-bottom: 20px; }

  } /* welcome-past */

  div.replay {

    text-align: center;

  }

}