@use "site-2015";
//@use "menu-2015";

$cdn_nfjs: 'https://cdn.nofluffjuststuff.com';

$sprite: 'uber2015/uber_sprite_2015_4.png';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans&family=Roboto&display=swap');

//@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Open+Sans|Droid+Sans);
//@import url('https://fonts.googleapis.com/css?family=Archivo|Rubik|Titillium+Web&display=swap');



$orange: #F7941D;
$orange2: #EF8F1C;
$dark-orange: #EE6E32;
$dark-bg: #333;
$yellow: #E7BC00;
$yellow2: #ecb800;
$dark-blue: #23496E;
$bg-color: #214054;

$mobile-break: 991px;
$mobile-size: 993px;

$large: 991px;
$medium: 767px;

$header-font-family: Lato, Arial, sans-serif;
$body-font-family: 'Open Sans',  Arial, sans-serif;

/* 2020 */

$green: #73A51E;
$light-blue: #07A7EA;
$gold: #C9991E;
$light-gray: #FAFAFA;

body {
   font-family: $body-font-family;
   font-size: 16px;
   background: #666;
}

div.body-wrapper {
  //max-width: 1500px;
  background: #fff;
  margin: 0 auto;
  border-left: 1px solid #999;
  border-right: 1px solid #999;
}

a { color: $dark-orange; }
a:hover { color: yellow; }


.btn-primary { background-color: $dark-bg; border-color: $dark-bg; }
.btn-primary:hover { background-color: rgba(51,51,51, 0.4); color: $dark-bg; border-color: $dark-bg; }



p a { color: $orange2; text-decoration: underline; }

h1, h2, h3  { font-family: $header-font-family; color: $dark-bg; font-weight: normal; margin: 0 0 10px 0; }

/* header */

div.rolenav {
  li { font-size: 12px; }
  ul {  margin: 0;  }
}

.navbar-speaker {  background-color: $dark-bg;  }


.navbar-default.navbar-speaker .navbar-nav>li a:hover { color: $orange; }



#nav-bar {
  background: #000;

  div.home-link-2020 {
     float: left;
      a {
        img {
          width: 270px;
        }
      }
  }

  #nfjs-drop-down-button {
    path { fill: #fff; }
  }

  .nav-links > ul {
    li {
      text-transform: none;
      a { color: #fff; }
      a.home-link { display: none; }
    }
  }

  .nav-links>ul li a {
     color: #fff;
  }
  .nav-links>ul li a:hover {
     color: $orange;
  }

  .nav-links>ul li.register a {
    background: $orange;
    color: yellow;
    padding: 5px 15px;
    border-radius: 4px;
  }

  @media only screen and (max-width: $mobile-size) {
    .nav-links > ul {
      li {
        a.home-link { display: block; }
      }
    }
  }

}




/* footer / login styles */


.speaker-circle-sm {
  img {
    border: 2px solid $dark-bg;
  }
}

div.speaker-link-group {

  a.twitter {  background: url($sprite) no-repeat -32px 0; }
  a.github {  background: url($sprite) no-repeat -361px 0; }
  a.web {  background: url($sprite) no-repeat -394px 0; }
}


a.twitter-small {
  background: url($sprite) no-repeat -32px 0;
}



/* content */

table.itemTable th { background-color: $dark-bg; color: #fff; font-weight: normal; font-size: 1em; }
table.itemTable tr:nth-child(odd) {
  background-color: #f1f1f1;
}

/* home-link */

//body.show-home div.home-link { display: none; }

/*
div.home-link {
  display: block; float: left;
  //a.logo { display: block; float: left; height: 42px; width: 48px; background: url($sprite) no-repeat -61px 0; content: ''; }
  h3 { display: block; float: left; color: #FFF; padding: 15px 0 0 10px; }
  h3 { font-family: 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif; font-weight: normal; }
  h3 a:hover { color: $orange2; }
}
 */


footer {
  //background: #344756 url('2020/uber/footer_bg.jpg') no-repeat top center;
  background: #000;
  text-align: left;
  color: #fff;
  padding: 30px 0 0 0;

  h4 { color: #fff;  }

  a { color: #fff; }
  a:hover { color: $orange; }

  img.logo { max-width: 200px; }

  ul {
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0;
  }

  address {
    font-size: 12px;
    line-height: 18px;
    margin: 20px 0 20px 0;
    color: $orange;
  }

  #site-version { font-size: 12px; text-align: center; }

  div.bottom {
     background: #000;
     color: #fff;
     padding: 15px;

    div.social-app-wrapper {
      text-align: right;
      div.social-app {
        display: inline-block;

        a {
          font-size: 32px;
          margin-right: 10px;
          color: #fff;
        }
        a:hover {
          color: $orange;
        }
      }

    }


  }

}



/* custom navbar collapse   */


@media (max-width: $mobile-break) {

  .container { width: 100%; padding: 0 15px; }

  div.top-wrapper {
      .container { padding: 0; }
      .navbar-collapse { background: #fff;  }

      div.home-link { display: none; }
      #top-nav>li.active>a {  color: #333; }
      #top-nav>li.active>a:hover {  color: $orange; }

    div.navbar.top {
        border-bottom: 1px solid #333;
        li.home { display: block; }
        a { padding: 5px 10px; }
        ul.nav>li>a { color: #333; }
        ul.nav>li>a:hover { color: $orange; }

    }
  }

  #nav-bar {
    .nav-links {
       ul>li>a {color: #000; }
    }
  }

  //body.show-home {
  //
  //  div.navbar.top {
  //      border-bottom: 0;
  //      li.register {
  //        a { background: transparent; border: 0; }
  //      }
  //  }
  //
  //
  //}

  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
    border-width: 0;
  }

  .navbar-toggle:hover {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255,0.1);

    ul.dropdown-menu {
      width: 100%;
    }

  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
  /*  margin-top: 7.5px; */
  }
  .navbar-nav>li {
    float: none;
  }

  div.rolenav .navbar-nav>li {
    float: left;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000;
  }
  .collapse.in{
    display:block !important;
  }
}

@media only screen and (max-width: 500px) {
    h1 { font-size: 24px; }
}


